.axie-selector {
  margin-top: 32px;
  flex-wrap: wrap;

  .axies-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__axie {
    padding: 16px;
    flex: 1;

    &:first-child {
      margin-right: 24px;
    }

    .text-field {

    }

    .axie-data {

      a {
        .ax-tooltip {
          margin-right: 6px;
        }
      }

      &__no-results {
        display: block;
        font-size: 14px;
        margin-bottom: 12px;
      }

      &__name {

      }

      &__image {
        display: block;
        max-width: 250px;
        margin-top: -32px;
        margin-bottom: -12px;
      }

      &__parts {
        h5 {
          margin: 0 0 12px;
        }
        &__part {
          display: flex;
          justify-content: space-between;
        }
      }

      &__similar-results {
        display: flex;
      }

      &__similar-axie {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #000;
        font-size: 14px;

        &__image {
          max-width: 100px;
        }
        &__price {
          margin-top: -10px;
        }
      }
    }
  }

  .calculate-btn {
    width: 100%;
    margin-top: 24px;
    background-color: #FFF;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }

  .axies-not-breedable {
    color: red;
    margin-bottom: 12px;
  }

  .data-fetch-err-message {
    margin-top: 12px;
    color: red;
  }
}
