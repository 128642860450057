.currency {
  margin-bottom: 24px;
  .refetch-btn {
    margin-top: 12px;
    width: auto;
  }

  .info-box {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;

    &__item {
      padding: 16px;
      margin-left: 24px;
      display: flex;
      align-items: center;

      .icon {
        margin-right: 12px;
      }
      .label {
        font-size: 16px;
      }
      .value {
        font-size: 16px;
        margin-left: 12px;
      }
    }

    @media only screen and (max-width: 644px) {
      &__item {
        margin-top: 12px;
      }
    }
  }
}
