.ctaction {
  padding: 12px;
  text-align: center;
  margin-bottom: 12px;

  &.ebc {
    .ebc-wrap {
      display: flex;
      justify-content: center;
      padding: 6px 32px;

      .ebc-img-wrap {
        width: 100px;
        padding: 16px;
        margin-right: 32px;
        img {
          max-width: 100%;
        }
      }

      .title {
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
      }

      @media only screen and (max-width: 800px) {
        flex-direction: column;

        .ebc-img-wrap {
          margin: 0 auto;
        }
      }

    }
  }

  .donate-options {
    display: flex;
    justify-content: space-around;

    @media only screen and (max-width: 800px) {
      flex-direction: column;

    }
  }

  .text-block {
    a {
      display: block;
      width: 150px;
      border: none;
      background-color: transparent;
      cursor: pointer;
      margin: 0 auto;

      img {
        display: block;
        max-width: 100%;
      }
    }

    p {
      margin: 12px 0;
    }

    .wallet-info {
      p {
        margin: 0 0 6px;

        > span {
          text-decoration: underline;
          cursor: pointer;
        }

        > svg {
          cursor: pointer;
        }
      }

      svg {
        font-size: 14px;
      }
    }
  }
}
