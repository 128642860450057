main {
  padding-top: 32px;
  padding-bottom: 42px;

  .calculate-grid {
    display: grid;
    grid-template-columns: 1fr 0.7fr;
    grid-column-gap: 24px;

    @media only screen and (max-width: 900px) {
      grid-template-columns: 1fr;
      .axie-selector {
        display: none;
      }
    }



    .calculation-results {

    }
  }
}

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    font-size: 12px;
  }
}
