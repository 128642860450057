.calculation-results {
  margin-top: 32px;
  padding: 16px;

  h3 {
    margin-top: 0;
    margin-bottom: 6px;
    font-weight: normal;
    font-size: 16px;
  }

  &__info-message {
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 12px;
    color: rgba(0,0,0,.5);
    text-align: center;
  }

  .cost-item {
    display: flex;
    .description {
      margin-top: 1px;
      margin-right: 6px;
      font-size: 14px;
    }
    .icon {
      margin-right:6px;
      max-width: 20px;
    }
    &--parts {
      display: flex;
      justify-content: space-around;
      margin-bottom: 12px;
    }
    &--total {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-top: 1px solid rgba(0,0,0,0.25);
      text-align: center;
      padding-top: 6px;
    }

    &__parents {
      margin-top: 6px;
    }
  }

  &__apply-btn {
    margin-top: 12px !important;
    width: 100%;
    color: #FFF !important;
    background-color: #3f51b5 !important;
  }

  &__number-of-breeds {
    .MuiFormGroup-root {
      flex-direction: row;
    }
  }

  &__axie-prices {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    .MuiFormControl-root {
      flex: 1;
      margin-right: 12px;
    }

    .ax-tooltip {
      font-size: 22px !important;
      align-self: flex-end;
      margin-bottom: 6px;
      margin-right: 12px;
    }
  }

  &__axie-sale-prices {
    display: flex;
    justify-content: space-between;

    .MuiFormControl-root {
      flex: 1;
      margin-right: 12px;
    }

    .ax-tooltip {
      font-size: 22px !important;
      align-self: flex-end;
      margin-bottom: 6px;
      margin-right: 12px;
    }
  }

  &__results {
    margin-top: 12px;
  }

  &__breed {
    margin-bottom: 24px;
    h6 {
      margin-top: 0;
      margin-bottom: 12px;

      .ax-tooltip {
        font-size: 14px !important;
      }
    }


  }

  &__total {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  &__egg-sale-inputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 12px;

    .MuiFormControl-root {
      flex: 1;
      margin-right: 12px;
    }

    .ax-tooltip {
      margin-right: 3px;
      margin-top: -2px;
    }

    .egg-sale-input {
      display: flex;
      margin-bottom: 12px;
      width: 47%;
      margin-right: auto;

      .ax-tooltip {

      }
    }

    .egg-sale-input:nth-child(odd) {
      margin-right: 12px;
    }
  }

  &__total-profit {
    .cost-item {
      justify-content: center;

      .value {
        margin-right: 12px;
      }
    }
  }
}
